import { render, staticRenderFns } from "./ConfigurableSettingsTable.vue?vue&type=template&id=500265d7&lang=pug&"
import script from "./ConfigurableSettingsTable.vue?vue&type=script&lang=js&"
export * from "./ConfigurableSettingsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports