import { render, staticRenderFns } from "./EditDockingStation.vue?vue&type=template&id=8993e816&scoped=true&lang=pug&"
import script from "./EditDockingStation.vue?vue&type=script&lang=js&"
export * from "./EditDockingStation.vue?vue&type=script&lang=js&"
import style0 from "./EditDockingStation.vue?vue&type=style&index=0&id=8993e816&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8993e816",
  null
  
)

export default component.exports